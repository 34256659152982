import './Footer.scss';

export default function Footer({ socials, qr }) {
    return (
        <div className="footer">
            <div className="social-contacts">
                <div><a href={`https://www.linkedin.com/in/${socials.linkedIn}`} target="_blank" rel="noreferrer" className="social social-linkedin"><i className="icon-linkedin"></i> {socials.linkedIn}</a></div>
                <div><a href={`https://github.com/${socials.github}`} className="social social-github" target="_blank" rel="noreferrer" ><i className="icon-github"></i> {socials.github}</a></div>
                <div><a href={`https://profiles.wordpress.org/${socials.wordpress}`} className="social social-wordpress" target="_blank" rel="noreferrer"><i className="icon-wordpress"></i> {socials.wordpress}</a></div>
                <div><a href={`https://twitter.com/${socials.twitter}`} className="social social-twitter" target="_blank" rel="noreferrer"><i className="icon-twitter"></i> {socials.twitter}</a></div>


            </div>
            <div className="qr-code-area">
                <img src={qr} className="qr-code" alt="QR Code for Abiral Neupane's Contact"/>
            </div>
        </div>
    );
}